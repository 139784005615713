import React from 'react';
import { FloatButton, Popover, Row, Space, Typography } from 'antd';
import { LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { config } from '@/config/index.js';

const { Text } = Typography;

const BASE_URL = config.SCAMS_REPORT_ASSETS_BASE_URL;

const data = [
  {
    title: 'What is Scams Report?',
    url: `${BASE_URL}/policy/what_is_scams_report.pdf`,
  },
  {
    title: 'Privacy Collection Notice',
    url: `${BASE_URL}/policy/privacy-collection-notice.pdf`,
  },
  {
    title: 'Terms of Use',
    url: `${BASE_URL}/policy/terms-of-use.pdf`,
  },
  {
    title: 'Privacy Policy',
    url: `${BASE_URL}/policy/privacy-policy.pdf`,
  },
  {
    title: 'Comments and Feedback',
    url: `${BASE_URL}/policy/comments-and-feedback.pdf`,
  },
];

const QuestionCenter = props => {
  const { isMobile } = props;

  const renderContent = () => {
    return (
      <Space direction="vertical" style={{ padding: '.3rem .5rem' }}>
        {data.map(item => {
          return (
            <Row key={item.title} style={{ margin: '0.1rem 0' }}>
              <Link to={item.url} target="_blank">
                <Space>
                  <LinkOutlined />
                  <Text>{item.title}</Text>
                </Space>
              </Link>
            </Row>
          );
        })}
      </Space>
    );
  };

  return (
    <Popover content={renderContent()} arrow={false} placement="bottomLeft">
      <FloatButton
        icon={<QuestionCircleOutlined />}
        type="default"
        style={{ insetInlineEnd: '3vw', bottom: isMobile ? '10vh' : '2.8vh' }}
      />
    </Popover>
  );
};

export default QuestionCenter;
