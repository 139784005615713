import React from 'react';
import { useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

const CustomAvatar = props => {
  const { userProfile } = useSelector(state => state.global);

  return <Avatar src={userProfile?.avatar} icon={<UserOutlined />} {...props} />;
};

export default CustomAvatar;
