import { Button, Row, Space, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import './index.css';

const { Text } = Typography;

const ChatFooter = props => {
  const { content, btnText, onClick } = props;
  const { themeMode } = useSelector(state => state.global);

  return (
    <div className={themeMode === 'dark' ? 'chat-footer-dark' : 'chat-footer'}>
      <Row justify="center">
        <Space size="large">
          <Text strong>{content}</Text>
          <Button size="large" type="primary" className="jump-btn" onClick={onClick}>
            {btnText}
          </Button>
        </Space>
      </Row>
    </div>
  );
};

export default ChatFooter;
