import { Tag } from 'antd';
import React from 'react';

const CountWord = props => {
  const { current = 0, max = 2000 } = props;

  return (
    <>
      <Tag>
        {current > max ? max : current}/{max} Character
      </Tag>
    </>
  );
};

export default CountWord;
