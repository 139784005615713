import React from 'react';
import { Col, Image, Row, Typography } from 'antd';
import logo from '@/assets/logo.png';
import '../index.css';
import { useOutletContext } from 'react-router-dom';

const HomeSenderView = () => {
  const { isMobile } = useOutletContext();

  return (
    <Row
      align="middle"
      justify="center"
      gutter={[10, 10]}
      style={{ height: '100%', width: isMobile ? '100%' : '65%', margin: '0 auto' }}
    >
      <div>
        <Col
          className="logo-img"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
            gap: '1rem',
          }}
        >
          <Image width={50} src={logo} preview={false} style={{ marginBottom: '8px' }} />
          <Typography.Title level={3}>Hello, I'm Scams.Report</Typography.Title>
        </Col>

        <Col style={{ textAlign: 'center' }}>
          <Typography.Text className="intro-text" style={{ fontSize: '1rem' }}>
            <i>Paste, Analyze, Act</i> – Verify suspicious content, get risk-level insights, and
            report scams to protect others.
          </Typography.Text>
        </Col>
      </div>
    </Row>
  );
};

export default HomeSenderView;
