import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  PlusOutlined,
  UserOutlined,
  EllipsisOutlined,
  RightSquareOutlined,
  LeftSquareOutlined,
  MessageOutlined,
  ProfileOutlined,
  DeleteOutlined,
  EditOutlined,
  MoonOutlined,
  SunOutlined,
  SettingOutlined,
  LogoutOutlined,
  AlignLeftOutlined,
  PlusSquareOutlined,
  AlignRightOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Image,
  Modal,
  Row,
  Col,
  Popover,
  Button,
  Dropdown,
  Spin,
  Tooltip,
  Typography,
  Space,
  Drawer,
} from 'antd';
import avatarDay from '@/assets/logo.png';
import { updateNameAPI } from '@/services/report.js';
import { deleteConversationApi, updateConversationNameAPI } from '@/services/chat.js';
import './index.css';
import { config } from '@/config/index.js';
import { logout, updateUserProfile } from '@/services/user.js';
import { useSelector, useDispatch } from 'react-redux';
import { checkLoginStatus } from '@/store/modules/authSlice.js';
import {
  deleteConversion,
  getConversionListThunk,
  getRepostLitThunk,
  getUserProfileThunk,
  setCurrentTitle,
  setThemeMode,
  updateConversionAttr,
  updateReportAttr,
} from '@/store/modules/globalSlice.js';
import Setting from './components/Setting.js';
import { useRequest } from 'ahooks';
import QuestionCenter from './components/QuestionCenter.js';
import CustomAvatar from '@/components/CustomAvatar/index.js';
import LoginModal from './components/LoginModal.js';
import RenameInput from './components/RenameInput.js';

const BASE_URL = config.SCAMS_REPORT_ASSETS_BASE_URL;

const { Content, Sider, Header } = Layout;
const { Text } = Typography;
const { confirm } = Modal;

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { externalRef } = useParams();
  const location = useLocation();

  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [menuSelectedKeys, setMenuSelectedKeys] = useState([]);
  const [menuOpenKeys, setMenuOpenKeys] = useState([]);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isMobileSiderOpen, setIsMobileSiderOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renameId, setRenameId] = useState('');
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [activePopoverId, setActivePopoverId] = useState('');

  const currentExternalRef = useRef(externalRef);

  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  const { userProfile, conversionList, reportList, themeMode, currentTitle } = useSelector(
    state => state.global
  );
  const { isLoggedIn } = useSelector(state => state.auth);

  const { run: updateThemeApi } = useRequest(updateUserProfile, {
    manual: true,
  });

  useEffect(() => {
    currentExternalRef.current = externalRef;
  }, [externalRef]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCollapsed(false);
    setIsMobileSiderOpen(false);
  }, [isMobile]);

  useEffect(() => {
    dispatch(checkLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      getInitialData();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const defaultMenuItems = [
      {
        key: '/Conversation',
        icon: <MessageOutlined />,
        label: 'Conversation',
        children: renderConversionItem(),
        type: collapsed ? '' : 'group',
      },
      {
        key: '/Report',
        icon: <ProfileOutlined />,
        label: 'Report',
        children: renderReportItem(),
        type: collapsed ? '' : 'group',
      },
    ];

    if (!isMobile && collapsed) {
      // 当折叠时，添加展开按钮到菜单项的第一个位置
      setMenuItems([
        {
          key: '/Expand',
          icon: <RightSquareOutlined />,
          label: 'Expand',
          onClick: () => setCollapsed(false),
        },
        ...defaultMenuItems,
      ]);
    } else {
      setMenuItems(defaultMenuItems);
    }
  }, [collapsed, conversionList, reportList, renameId, activePopoverId]);

  useEffect(() => {
    setDefaultMenuKeys();
    if (isMobile) {
      setIsMobileSiderOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      setHeaderTitle();
    } else {
      dispatch(setCurrentTitle('Scams.Report'));
    }
  }, [location.pathname, conversionList, reportList]);

  const setHeaderTitle = () => {
    const pathname = location.pathname;
    if (/\/chat\/*/.test(pathname)) {
      const target = conversionList.find(item => item.externalRef === currentExternalRef.current);
      dispatch(setCurrentTitle(target?.name));
    } else if (/\/report\/*/.test(pathname)) {
      const target = reportList.find(item => item.externalRef === currentExternalRef.current);
      dispatch(setCurrentTitle(target?.reportName));
    } else {
      dispatch(setCurrentTitle('Scams.Report'));
    }
  };

  const setDefaultMenuKeys = () => {
    const pathname = location.pathname;
    if (/\/chat\/*/.test(pathname)) {
      !isMobile && setMenuOpenKeys(['/Conversation']);
      setMenuSelectedKeys(location.pathname);
    } else if (/\/report\/*/.test(pathname)) {
      !isMobile && setMenuOpenKeys(['/Report']);
      setMenuSelectedKeys(location.pathname);
    } else {
      setMenuSelectedKeys([]);
      setMenuOpenKeys([]);
    }
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      dispatch(getUserProfileThunk());
      dispatch(getConversionListThunk()).then(() => {
        setDefaultMenuKeys();
      });
      dispatch(getRepostLitThunk()).then(() => {
        setDefaultMenuKeys();
      });
    } catch (error) {
      console.error('Error during data fetching:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuEllipsisClick = e => {
    e.stopPropagation();
  };

  const popoverCommonConfig = {
    overlayInnerStyle: { padding: '.1rem' },
    trigger: 'click',
    placement: 'bottomLeft',
    arrow: false,
  };

  const renderConversionItem = () => {
    return conversionList.map(item => ({
      key: `/chat/${item.externalRef}`,
      label: (
        <Row gutter={[10, 10]} style={{ width: '20rem' }}>
          <Tooltip
            title={isMobile ? null : <span style={{ whiteSpace: 'nowrap' }}>{item.name}</span>}
            overlayStyle={{ maxWidth: '30vw' }}
          >
            <Col flex="1" style={{ overflow: 'hidden', marginRight: '.5rem' }}>
              {renameId === item.externalRef ? (
                <RenameInput
                  menuName={item.name}
                  externalRef={item.externalRef}
                  onSave={(id, val) => {
                    updateConversationNameAPI({ externalRef: id, name: val });
                    dispatch(updateConversionAttr({ conversionId: id, data: { name: val } }));
                    setRenameId(null);
                  }}
                />
              ) : (
                <>
                  {item.reportId ? (
                    <span style={{ color: '#FF6633' }}>{item.name}</span>
                  ) : (
                    item.name
                  )}
                </>
              )}
            </Col>
          </Tooltip>

          <Col flex="24px">
            <Popover
              content={editConversation(item.externalRef, item.reportId, item.name)}
              open={item.externalRef === activePopoverId}
              onOpenChange={visible => {
                setActivePopoverId(visible ? item.externalRef : null);
              }}
              {...popoverCommonConfig}
            >
              <EllipsisOutlined onClick={handleMenuEllipsisClick} />
            </Popover>
          </Col>
        </Row>
      ),
    }));
  };

  const renderReportItem = () => {
    return reportList.map(item => ({
      key: `/report/${item.externalRef}`,
      label: (
        <Row gutter={[10, 10]} style={{ width: '20rem' }}>
          <Tooltip
            title={
              isMobile ? null : <span style={{ whiteSpace: 'nowrap' }}>{item.reportName}</span>
            }
            overlayStyle={{ maxWidth: '30vw' }}
          >
            <Col flex="1" style={{ overflow: 'hidden', marginRight: '.5rem' }}>
              {renameId === item.externalRef ? (
                <RenameInput
                  menuName={item.reportName}
                  externalRef={item.externalRef}
                  onSave={(id, val) => {
                    updateNameAPI({ externalRef: id, reportName: val });
                    dispatch(updateReportAttr({ reportId: id, data: { reportName: val } }));
                    setRenameId(null);
                  }}
                />
              ) : (
                <span>{item.reportName}</span>
              )}
            </Col>
          </Tooltip>

          <Col flex="24px">
            <Popover
              content={editReport(item.externalRef, item.reportName)}
              open={item.externalRef === activePopoverId}
              onOpenChange={visible => {
                setActivePopoverId(visible ? item.externalRef : null);
              }}
              {...popoverCommonConfig}
            >
              <EllipsisOutlined onClick={handleMenuEllipsisClick} />
            </Popover>
          </Col>
        </Row>
      ),
    }));
  };

  const deleteConversation = id => {
    if (currentExternalRef.current && id === currentExternalRef.current) {
      navigate('/');
    }
    dispatch(deleteConversion(id));
    deleteConversationApi(id);
  };

  const getConversionItems = hasReport => {
    return [
      {
        key: 'conversion_edit',
        label: 'Rename',
        icon: <EditOutlined />,
      },
      {
        key: 'conversion_delete',
        label: 'Delete',
        icon: <DeleteOutlined />,
        danger: true,
        disabled: hasReport,
      },
    ];
  };

  const deleteConfirm = (title, callback) => {
    confirm({
      title: 'Delete Chat?',
      icon: <ExclamationCircleFilled />,
      content: (
        <span>
          This will delete <b>{title}</b>
        </span>
      ),
      onOk() {
        callback();
      },
      okButtonProps: {
        danger: true,
      },
      onCancel() {},
      okText: 'Delete',
    });
  };

  const handleMenuClick = (key, id, e, name = '') => {
    e.stopPropagation();
    setActivePopoverId(null);
    switch (key) {
      case 'conversion_edit':
        setRenameId(id);
        break;
      case 'conversion_delete':
        deleteConfirm(name, () => {
          deleteConversation(id);
        });
        break;
      case 'report_edit':
        setRenameId(id);
        break;
      default:
        break;
    }
  };

  const editConversation = (id, hasReport, name) => (
    <div>
      <Menu
        items={getConversionItems(hasReport)}
        selectable={false}
        onClick={({ key, domEvent }) => handleMenuClick(key, id, domEvent, name)}
      />
    </div>
  );

  const getReportItems = () => {
    return [
      {
        key: 'report_edit',
        label: 'Rename',
        icon: <EditOutlined />,
      },
    ];
  };

  const editReport = id => (
    <div>
      <Menu
        items={getReportItems()}
        selectable={false}
        onClick={({ key, domEvent }) => handleMenuClick(key, id, domEvent)}
      />
    </div>
  );

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleLogin = provider => {
    window.location.href = `${config.API_BASE_URL}/oauth2/authorization/${provider}`;
    setIsLoginModalOpen(false);
  };

  const handleSignout = () => {
    logout().then(() => {
      navigate('/');
      window.location.reload();
    });
  };

  const onMenuClick = route => {
    if (route.key !== '/Expand') {
      navigate(route.key);
      setMenuSelectedKeys(route.key);
    }
    if (isMobile) {
      setIsMobileSiderOpen(false);
    }
  };

  const onOpenChange = openKeys => {
    setMenuOpenKeys(openKeys);
  };

  const renderUserMenu = useCallback(() => {
    const DefaultMenu = ({ children }) => (
      <Menu style={{ width: '200px', textAlign: 'center' }}>
        <Menu.Item key="0" icon={<CustomAvatar />}>
          &nbsp;&nbsp;&nbsp;
          {userProfile && userProfile.name}
        </Menu.Item>
        <Menu.Divider />
        {children}
        <Menu.Item
          key="1"
          icon={<LogoutOutlined style={{ fontSize: '1.7em' }} />}
          onClick={handleSignout}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Signout
        </Menu.Item>
      </Menu>
    );
    if (collapsed)
      return (
        <DefaultMenu>
          <Menu.Item
            key="2"
            icon={<SettingOutlined style={{ fontSize: '1.7em' }} />}
            onClick={() => setIsSettingModalOpen(true)}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setting
          </Menu.Item>
          <Menu.Divider />
        </DefaultMenu>
      );
    return <DefaultMenu />;
  }, [collapsed, userProfile]);

  const handleUpdateUser = async changeToDark => {
    const appearance = changeToDark ? 'dark' : 'light';
    dispatch(setThemeMode(appearance));
    updateThemeApi({ appearance });
  };

  const renderMenu = () => {
    return (
      <>
        <Row style={{ width: '100%' }}>
          <Button
            type="primary"
            iconPosition="end"
            icon={<PlusOutlined />}
            style={{
              width: collapsed ? '50%' : '90%',
              margin: '0 auto',
              borderRadius: '1rem',
              marginBottom: '.3rem',
            }}
            onClick={() => navigate('/')}
          >
            {!collapsed && 'New Chat'}
          </Button>
        </Row>
        <Menu
          selectedKeys={menuSelectedKeys}
          openKeys={menuOpenKeys}
          mode="inline"
          onClick={onMenuClick}
          onOpenChange={onOpenChange}
          items={menuItems}
          style={{ height: '80vh', overflow: 'auto' }}
        />
      </>
    );
  };

  const renderSiderHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: collapsed ? 'center' : 'flex-start',
          padding: '20px 10px',
        }}
      >
        <Image
          width={50}
          src={avatarDay}
          preview={false}
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        {!collapsed && (
          <Space size="large">
            <label
              className="intro-text"
              style={{ marginLeft: '25px', fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
              <Text style={{ fontSize: '18px' }}>Scams Report</Text>
            </label>
            <Text>
              <LeftSquareOutlined
                className="sider-trigger"
                style={{ marginLeft: 'auto', cursor: 'pointer', float: 'right' }}
                onClick={() => setCollapsed(true)}
              />
            </Text>
          </Space>
        )}
      </div>
    );
  };

  const renderSiderFooter = () => {
    return (
      <div className="login-footer">
        {!userProfile && (
          <UserOutlined
            type="primary"
            block
            onClick={handleLoginModalOpen}
            style={{ fontSize: '1.7em' }}
          >
            Login
          </UserOutlined>
        )}
        {userProfile && (
          <div className="user-actions">
            <Dropdown overlay={renderUserMenu()}>
              <Button
                icon={<CustomAvatar />}
                style={{
                  border: 'none',
                  boxShadow: 'none',
                  backgroundColor: 'inherit',
                  margin: collapsed ? '0 auto' : '',
                }}
              >
                {!collapsed && userProfile.name}
              </Button>
            </Dropdown>

            {!collapsed && (
              <div>
                {themeMode === 'dark' ? (
                  <Text>
                    <SunOutlined className="icon-style" onClick={() => handleUpdateUser(false)} />
                  </Text>
                ) : (
                  <Text>
                    <MoonOutlined className="icon-style" onClick={() => handleUpdateUser(true)} />
                  </Text>
                )}
                <Text>
                  <SettingOutlined
                    className="icon-style"
                    onClick={() => setIsSettingModalOpen(true)}
                  />
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSider = () => {
    return (
      <Sider
        theme={themeMode}
        collapsible
        width={260}
        collapsed={collapsed}
        trigger={null}
        style={{
          height: '100vh',
          position: 'relative',
        }}
      >
        <div>
          {renderSiderHeader()}
          {renderMenu()}
          {renderSiderFooter()}
        </div>
      </Sider>
    );
  };

  const renderHeaderRight = () => {
    if (isLoggedIn) {
      return (
        <Link to="/">
          <Button type="link" ghost icon={<PlusSquareOutlined />}></Button>
        </Link>
      );
    }
    return (
      <Button
        type="primary"
        size="small"
        style={{ borderRadius: '1rem' }}
        onClick={handleLoginModalOpen}
      >
        Login
      </Button>
    );
  };

  const renderMenuLayout = () => {
    if (isMobile) {
      return (
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: themeMode === 'dark' ? '' : '#fff',
            padding: '0 .3rem',
            position: 'sticky',
            top: 0,
          }}
        >
          <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Col span={4}>
              <Button
                type="link"
                ghost
                icon={<AlignLeftOutlined />}
                onClick={() => setIsMobileSiderOpen(true)}
              ></Button>
            </Col>
            <Col
              span={12}
              style={{
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <Typography.Text strong>{currentTitle}</Typography.Text>
            </Col>
            <Col span={4} style={{ textAlign: 'end' }}>
              {renderHeaderRight()}
            </Col>
          </Row>
        </Header>
      );
    }
    return renderSider();
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <Layout>
        {renderMenuLayout()}

        <Layout>
          <Content
            style={{
              height: isMobile ? 'calc(100svh - 48px)' : '100vh',
              display: 'flex',
              flexDirection: 'column',
              margin: '0 auto',
              overflowY: 'auto',
              overflowX: 'hidden',
              width: '100%',
            }}
          >
            <Outlet
              context={{
                isMobile,
              }}
            />
          </Content>
        </Layout>

        <Drawer
          placement="left"
          width="60%"
          closable={false}
          open={isMobileSiderOpen}
          onClose={() => setIsMobileSiderOpen(false)}
          styles={{ body: { padding: 0 } }}
        >
          <Row justify="end">
            <Button
              type="link"
              ghost
              icon={<AlignRightOutlined />}
              onClick={() => setIsMobileSiderOpen(false)}
            ></Button>
          </Row>
          <Row>{renderMenu()}</Row>
          <Row>{renderSiderFooter()}</Row>
        </Drawer>

        <LoginModal
          isLoginModalOpen={isLoginModalOpen}
          handleLoginModalClose={handleLoginModalClose}
          handleLogin={handleLogin}
        />

        {userProfile && (
          <Setting isModalOpen={isSettingModalOpen} setIsModalOpen={setIsSettingModalOpen} />
        )}
        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: isMobile ? '50%' : collapsed ? 'calc(50% + 10px)' : 'calc(50% + 130px)',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            fontSize: '13px',
            width: 'max-content',
            transition: 'left 0.3s ease',
            color: 'gray',
          }}
        >
          {isMobile && 'Scams.Report may error. See '}
          {!isMobile &&
            'Scams.Report uses generative AI, which may occasionally produce errors. Please review our '}
          <a href={`${BASE_URL}/policy/terms-of-use.pdf`} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
        </div>

        <QuestionCenter isMobile={isMobile} />
      </Layout>
    </Spin>
  );
};

export default HomePage;
