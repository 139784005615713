import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Image, Card, Drawer } from 'antd';
import errorImg from '@/assets/screenshotError.png';
import loadingImg from '@/assets/loading.png';
import { ReportStatus } from './reportState.js';
import { ReportTimeline } from './reportTimeline.js';
import { ClusterOutlined } from '@ant-design/icons';
import './TabPane.css';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getReportCta } from '@/services/report.js';
import { useSelector } from 'react-redux';
import { useRequest } from 'ahooks';

const TabPane = props => {
  const { t } = useTranslation();
  const timerRef = useRef(null);

  const details = props.name;

  const [colorText, setColorText] = useState(''); // Acceptance Status 颜色
  const { refClearInterval } = useSelector(state => state.chat);

  const isShowImage = details.isUrl === 1 ? true : false;
  const isShowPhone = details.isUrl === 3 ? true : false;

  const { data, runAsync } = useRequest(getReportCta, {
    manual: true,
  });

  useEffect(() => {
    runAsync(details.externalRef);

    // Acceptance Status 颜色
    if (details.reportCtaRelaState === 'Pending Verification') {
      return setColorText('blue');
    } else if (details.reportCtaRelaState === 'Verified') {
      return setColorText('green');
    } else if (details.reportCtaRelaState === 'Resolved') {
      return setColorText('orange');
    } else if (details.reportCtaRelaState === 'Closed') {
      return setColorText('#88888bfa');
    } else {
      return setColorText('blue');
    }
  }, []);

  // 使用 useEffect 监听 img 的变化
  useEffect(() => {
    if (data?.img == 0) {
      runAsync(details.externalRef); // 立即调用一次
      timerRef.current = setInterval(() => runAsync(details.externalRef), 10000);
    }

    // 清理定时器
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [data?.img, details, refClearInterval]); // 监听 img 的变化

  // 状态弹窗
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const columnSpan = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col {...columnSpan} xxl={isShowImage ? 12 : 24}>
          <Row gutter={[10, 10]}>
            <Col {...columnSpan} xxl={isShowImage ? 24 : 12}>
              {/* Details */}
              <Card title={t('report.tabPane.Details')} type="inner" style={{ minHeight: '16rem' }}>
                <Row>
                  <Col span={12}>{t('report.tabPane.Details.feedbackTime')} : </Col>
                  <Col span={12}>{dayjs(details.feedbackTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
                </Row>
                <Row>
                  <Col span={12} style={{ wordBreak: 'break-word' }}>
                    {t('report.tabPane.Details.first')} :
                  </Col>
                  <Col span={12}>{dayjs(details.firstTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
                </Row>
                <Row>
                  <Col span={12}>User Region :</Col>
                  <Col span={12}>{details.userArea}</Col>
                </Row>
                <Row>
                  <Col span={12}>Type :</Col>
                  <Col span={12}>{details.reportCtaRelaType}</Col>
                </Row>
                <Row>
                  <Col span={12}>Identifying content :</Col>
                  <Col span={12} style={{ wordBreak: 'break-word' }}>
                    {details.isUrl === 1 ? (
                      <a> {details.identifyContent} </a>
                    ) : (
                      details.identifyContent
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>{isShowImage ? 'Location of the website :' : 'Location :'}</Col>
                  <Col span={12}>{isShowImage ? details.websiteArea : details.phoneArea}</Col>
                </Row>
                <Row>
                  <Col span={12}>Related brands :</Col>
                  <Col span={12}>{details.relatedBrands}</Col>
                </Row>
              </Card>
            </Col>
            <Col {...columnSpan} xxl={isShowImage ? 24 : 12}>
              {/* Status */}
              <Card
                type="inner"
                title={
                  <div>
                    Status
                    <ClusterOutlined
                      style={{ color: 'green', float: 'right' }}
                      onClick={showDrawer}
                    />
                  </div>
                }
                style={{ height: '16rem' }}
              >
                <Row>
                  <Col span={12}>Acceptance Status :</Col>
                  <Col span={12} style={{ color: `${colorText}` }}>
                    {details.reportCtaRelaState}
                  </Col>
                </Row>
                <br />
                <br />
                <Row className="status_col">
                  <ReportStatus name={details.reportCtaRelaState} />
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        {/* img imgSpan*/}
        {isShowImage && (
          <Col {...columnSpan}>
            <Card
              title="Screenshot"
              type="inner"
              style={{
                maxHeight: 'calc(32rem + 20px)',
                overflow: 'hidden',
              }}
            >
              {data?.img == 1 ? (
                <Image
                  src={data?.imgUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              ) : data?.img == 3 ? (
                <Image src={errorImg} />
              ) : (
                <Image src={loadingImg} />
              )}
            </Card>
          </Col>
        )}

        {isShowImage && (
          /* URL and 域名 */
          <Col span={24}>
            <Card title="Service Provider Information" type="inner">
              <Row>
                <Col {...columnSpan} xxl={8}>
                  <Card className="provider_card" title="Network provider" bordered={false}>
                    {details.networkProvider}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={8}>
                  <Card className="provider_card" title="Domain registrar" bordered={false}>
                    {details.domainRegistrar}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={8}>
                  <Card className="provider_card" title="Domain name resolver" bordered={false}>
                    {details.domainNameResolver}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        {isShowPhone && (
          /* 电话 and 邮件 */
          <Col span={24}>
            <Card title="Phone Number Provider" type="inner">
              <Row justify="center">
                <Col {...columnSpan} xxl={4}>
                  <Card className="provider_card" title="Allocatee" bordered={false}>
                    {details.allocatee}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={4}>
                  <Card className="provider_card" title="Allocation Date" bordered={false}>
                    {details.allocationDate}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={4}>
                  <Card className="provider_card" title="Latest Holder" bordered={false}>
                    {details.latestHolder}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={4}>
                  <Card className="provider_card" title="Latest Transfer Date" bordered={false}>
                    {details.latestTransferDate}
                  </Card>
                </Col>
                <Col {...columnSpan} xxl={4}>
                  <Card className="provider_card" title="Numbering Area" bordered={false}>
                    {details.numberingArea}
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>

      {/* 状态弹窗 */}
      <Drawer title="Takedown timeline" onClose={onClose} open={open}>
        <ReportTimeline name={details.reportCtaRelaState} />
      </Drawer>
    </>
  );
};

export default TabPane;
