import { Button, Checkbox, Image, Modal } from 'antd';
import React, { useState } from 'react';
import loginWhite from '@/assets/login-white.png';
import { GoogleOutlined, FacebookOutlined, WindowsOutlined } from '@ant-design/icons';

const LoginModal = props => {
  const { isLoginModalOpen, handleLoginModalClose, handleLogin } = props;

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  return (
    <Modal
      open={isLoginModalOpen}
      onCancel={handleLoginModalClose}
      footer={null}
      centered
      width={400}
    >
      <div style={{ textAlign: 'center' }}>
        <br />
        <h2>Scams Report</h2>
        <br />
        <h1>Welcome!</h1>
        <br />
        <Image src={loginWhite} preview={false} width={150} />
        <br />
        <br />
        <p>Prevent, Detect, Protect</p>
        <br />
        <Checkbox onChange={e => setIsTermsAgreed(e.target.checked)}>
          I agree to the
          <a
            href="https://www.scams.report/policy/terms-of-use.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {' '}
            Terms of Use
          </a>
          .
        </Checkbox>
        <br />
        <br />
        <Button
          icon={<GoogleOutlined />}
          type="primary"
          className="login-button google"
          onClick={() => handleLogin('google')}
          disabled={!isTermsAgreed}
        >
          Continue with Google
        </Button>
        <Button
          icon={<FacebookOutlined />}
          type="primary"
          className="login-button facebook"
          onClick={() => handleLogin('facebook')}
          disabled={!isTermsAgreed}
        >
          Continue with Facebook
        </Button>
        <Button
          icon={<WindowsOutlined />}
          type="primary"
          className="login-button microsoft"
          onClick={() => handleLogin('microsoft')}
          disabled={!isTermsAgreed}
        >
          Continue with Microsoft
        </Button>
      </div>
    </Modal>
  );
};

export default LoginModal;
