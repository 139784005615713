import { Input, notification } from 'antd';
import React, { useState } from 'react';

const RenameInput = ({ menuName, externalRef, onSave }) => {
  const [value, setValue] = useState(menuName);

  const handleSave = e => {
    e.stopPropagation();
    if (!value.trim() || value.length < 1) {
      showValidationError();
      return;
    }
    onSave(externalRef, value);
  };
  const showValidationError = () => {
    notification.open({
      message: 'Name modification prompt',
      description: 'The length of the name should be between 1 and 20.',
      duration: 3,
    });
  };

  return (
    <Input
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleSave}
      onPressEnter={handleSave}
      autoFocus
    />
  );
};

export default RenameInput;
