import axios from 'axios';
import { notification } from 'antd';
import { config } from '@/config/index.js';

const { API_BASE_URL } = config;
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

axios.defaults.withCredentials = true;
// 创建 axios 实例
const request = axios.create({
  baseURL: API_BASE_URL + '/client',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    const { response } = error;
    if (axios.isCancel(error)) {
      notification.error({
        message: `Request canceled`,
      });
      return;
    }
    const errorText = response?.data?.message || codeMessage[response?.status] || 'Request error';
    const status = response?.status;
    notification.error({
      message: `Request Error with ${status}`,
      description: errorText,
    });
    throw error;
  }
);

const enhancedRequest = (url, options) => {
  const { signal, ...restOptions } = options || {};

  // 如果有 signal，且是标准 AbortSignal 对象
  if (signal && signal instanceof AbortSignal) {
    return request(
      url,
      {
        ...restOptions,
        signal,
      },
      { timeout: 60000 }
    );
  }

  // 如果没有 signal，直接发起请求
  return request(url, restOptions);
};

export default enhancedRequest;
