export const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('ChatStorage', 2);

    request.onupgradeneeded = event => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('unsavedMessages')) {
        const store = db.createObjectStore('unsavedMessages', { keyPath: 'id' });
        store.createIndex('binaryData', 'files.data', { unique: false });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = e => reject(e);
  });
};

export const saveMessageToDB = async data => {
  try {
    const db = await openDB();
    const tx = db.transaction('unsavedMessages', 'readwrite');
    tx.objectStore('unsavedMessages').put({ id: 'current', ...data });
    return new Promise(resolve => (tx.oncomplete = resolve));
  } catch (e) {
    console.error('Get message error:', e);
  }
};

export const getMessageFromDB = async () => {
  try {
    const db = await openDB();
    const tx = db.transaction('unsavedMessages');
    const request = tx.objectStore('unsavedMessages').get('current');
    return new Promise(resolve => {
      request.onsuccess = () => resolve(request.result);
    });
  } catch (e) {
    console.error('Get message error:', e);
    return null;
  }
};

export const clearMessageFromDB = async () => {
  const db = await openDB();
  const tx = db.transaction('unsavedMessages', 'readwrite');
  tx.objectStore('unsavedMessages').delete('current');
};
