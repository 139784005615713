import { Bubble } from '@ant-design/x';
import React, { useState } from 'react';
import { Image, Row, Space, Typography } from 'antd';
import RiskBubble from './RiskBubble.js';
import '../index.css';
import { useSelector } from 'react-redux';
import logo from '../../../assets/logo.png';
import { UserOutlined } from '@ant-design/icons';

const { Text } = Typography;

const commonConfig = {
  styles: { content: { maxWidth: '710px' } },
};

const CustomBubbleRender = props => {
  const {
    message,
    role,
    createdAt,
    typing = {
      step: 5,
      interval: 30,
    },
  } = props;

  const [isComplete, setIsComplete] = useState(false);

  const { userProfile } = useSelector(state => state.global);

  const roles = {
    assistant: {
      placement: 'start',
      avatar: {
        src: logo,
      },
      ...commonConfig,
    },
    user: {
      placement: 'end',
      avatar: {
        src: userProfile?.avatar,
        icon: <UserOutlined />,
      },
      ...commonConfig,
    },
  };

  const renderCtaWithWhitelist = ctas => {
    if (!ctas) return [];
    return ctas.map(item => {
      if (item.isWhite) return `${item.cta}  💎`;
      return item.cta;
    });
  };

  const fields = data => {
    return [
      {
        label: 'Involved Organization',
        content: data.involved_organization,
        value: 'involved_organization',
      },
      {
        label: 'Actionable Insights',
        content: renderCtaWithWhitelist(data.formatterCtasList)?.join('\n\n'),
        value: 'ctas',
      },
      {
        label: 'Possible Incident Type',
        content: data.possible_incident_type,
        value: 'possible_incident_type',
      },
      { label: 'Explanation', content: data.explanation, value: 'explanation' },
      { label: 'Advice', content: data.advice, value: 'advice' },
    ].filter(field => field.content);
  };

  const renderFieldsToMarkdown = fields => {
    return fields.map(field => `### ${field.label}\n\n${field.content}\n`).join('\n');
  };

  const getText = () => {
    switch (message.type) {
      case 'text':
        return message.content;
      case 'risk':
        return renderFieldsToMarkdown(fields(message.content));
      case 'image':
        return message.content.text;
      default:
        break;
    }
  };

  const renderView = content => <Typography>{content}</Typography>;

  const renderImageView = (content, imageUrls = []) => {
    return (
      <Typography>
        <Row>{content}</Row>
        <Image.PreviewGroup>
          {imageUrls.map(item => (
            <Image key={item} width={200} src={item} />
          ))}
        </Image.PreviewGroup>
      </Typography>
    );
  };

  const messageRender = msg => {
    switch (message.type) {
      case 'text':
        return renderView(msg);
      case 'risk':
        return (
          <RiskBubble
            content={msg}
            score={message.content?.risk_score}
            ctas={message.content?.formatterCtasList}
            isComplete={isComplete}
          />
        );
      case 'image':
        return <>{renderImageView(msg, message.content.imageUrls)}</>;
      default:
        break;
    }
  };

  const onTypingComplete = () => {
    setIsComplete(true);
  };

  const renderDate = () => {
    const date = createdAt ? new Date(createdAt) : new Date();
    return <Typography className="date">{date.toLocaleString()}</Typography>;
  };

  return (
    <Bubble
      header={renderDate()}
      typing={typing}
      content={getText()}
      messageRender={messageRender}
      onTypingComplete={onTypingComplete}
      footer={
        role === 'assistant' && (
          <Space>
            <Text copyable={{ text: getText() }} />
          </Space>
        )
      }
      {...roles[role]}
    />
  );
};

export default CustomBubbleRender;
