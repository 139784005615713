import {
  Col,
  Divider,
  Form,
  Image,
  List,
  Modal,
  notification,
  Row,
  Segmented,
  Select,
  Space,
  Upload,
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MoonOutlined, SunOutlined } from '@ant-design/icons';
import './index.css';
import google from '@/assets/google.svg';
import microsoft from '@/assets/microsoft.svg';
import facebook from '@/assets/facebook.svg';
import { useRequest } from 'ahooks';
import { updateUserProfile } from '@/services/user.js';
import { setThemeMode, updateUserProfileState } from '@/store/modules/globalSlice.js';
import dayjs from 'dayjs';
import { getAvatarSignedUrl, uploadToS3WithCache } from '@/services/attachment.js';
import CustomAvatar from '@/components/CustomAvatar/index.js';

const OPTIONS = ['General', 'Account', 'Login History'];

const APPEARANCE = [
  {
    value: 'light',
    label: (
      <Space>
        <SunOutlined />
        Light
      </Space>
    ),
  },
  {
    value: 'dark',
    label: (
      <Space>
        <MoonOutlined />
        Dark
      </Space>
    ),
  },
];

const LANGUAGE = [
  {
    value: 'English',
    label: 'English',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
];

const THIRD_APP = {
  google,
  microsoft,
  facebook,
};

const Setting = props => {
  const { isModalOpen, setIsModalOpen } = props;

  const [currentTab, setCurrentTab] = useState(OPTIONS[0]);

  const { userProfile } = useSelector(state => state.global);

  const dispatch = useDispatch();

  const { run } = useRequest(updateUserProfile, {
    manual: true,
  });

  const handleCancel = () => {
    setIsModalOpen(false);
    setCurrentTab(OPTIONS[0]);
  };

  const renderGeneral = () => {
    return (
      <>
        <Form.Item name="appearance" label="Appearance" style={{ width: '100%' }}>
          <Select options={APPEARANCE} className="setting-item-right" />
        </Form.Item>
        <Divider />
        <Form.Item name="language" label="Language" style={{ width: '100%' }}>
          <Select options={LANGUAGE} className="setting-item-right" />
        </Form.Item>
        <Divider />
      </>
    );
  };

  const handleFileUpload = async fileInfo => {
    const { file } = fileInfo;
    try {
      const url = await getAvatarSignedUrl(file);
      await uploadToS3WithCache(url, file);
      dispatch(
        updateUserProfileState({ avatar: `${userProfile.avatar}?t=${new Date().getTime()}` })
      );
      notification.success({ message: 'Upload successfully' });
    } catch (error) {
      notification.error({ message: error.message });
    }
  };

  const renderAccount = () => {
    return (
      <>
        <Form.Item name="avatar" label="Avatar" style={{ width: '100%' }}>
          <Upload
            name="avatar"
            showUploadList={false}
            accept=".jpg,.jpeg,.png"
            className="setting-item-right-account"
            customRequest={handleFileUpload}
          >
            <CustomAvatar size={40} />
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item label="Username" style={{ width: '100%' }}>
          <span className="setting-item-right-account">{userProfile.name}</span>
        </Form.Item>
        <Divider />
        <Form.Item label="Email" style={{ width: '100%' }}>
          <span className="setting-item-right-account">{userProfile.email}</span>
        </Form.Item>
        <Divider />
        <Form.Item label="Third-Party App" style={{ width: '100%' }}>
          <span className="setting-item-right-account">
            <Image width={80} src={THIRD_APP[userProfile.source]} preview={false} />
          </span>
        </Form.Item>
        <Divider />
      </>
    );
  };

  const renderLoginHistory = () => {
    return (
      <List
        style={{ width: '95%', margin: '0 auto', textAlign: 'center' }}
        dataSource={userProfile?.logHistory}
        renderItem={item => (
          <List.Item>
            <Row style={{ width: '100%' }} justify="space-around">
              <Col span={7}>{item.deviceInfo}</Col>
              <Col span={7}>{item.ipCountryCode}</Col>
              <Col span={7}>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Col>
            </Row>
          </List.Item>
        )}
      />
    );
  };

  const renderContent = () => {
    switch (currentTab) {
      case 'General':
        return renderGeneral();
      case 'Account':
        return renderAccount();
      case 'Login History':
        return renderLoginHistory();
    }
  };

  const handleOnFieldsChange = changedFields => {
    const filed = changedFields[0].name[0];
    const value = changedFields[0].value;
    const payload = {};

    if (filed === 'avatar') return;

    Object.assign(payload, { [filed]: value });
    if (filed === 'appearance') {
      dispatch(setThemeMode(value));
    }
    run(payload);
  };

  return (
    <>
      <Modal
        title="Setting"
        width="40%"
        style={{ minWidth: '380px', top: '220px' }}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Segmented
          defaultChecked={OPTIONS[0]}
          onChange={val => setCurrentTab(val)}
          style={{ width: '100%', marginBottom: '1rem' }}
          options={OPTIONS}
          block
        />
        <Form
          initialValues={userProfile}
          size="small"
          layout="inline"
          onFieldsChange={handleOnFieldsChange}
          colon={false}
        >
          {renderContent}
        </Form>
      </Modal>
    </>
  );
};

export default Setting;
