import { addReportAPI } from '@/services/report.js';
import { getRepostLitThunk, updateConversionReportId } from '@/store/modules/globalSlice.js';
import { useRequest } from 'ahooks';
import { Button, Checkbox, Col, Modal, notification, Progress, Row, Space } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

const RiskBubble = props => {
  const { score, content, ctas = [], isComplete } = props;
  const { externalRef } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reportSelectionVisible, setReportSelectionVisible] = useState(false);
  const [selectedCtas, setSelectedCtas] = useState([]);

  const { conversionList } = useSelector(state => state.global);

  const { loading, runAsync: runAddReport } = useRequest(addReportAPI, {
    manual: true,
  });

  const addReport = async () => {
    if (!externalRef) return;
    let ctaIdList = [];
    if (ctas.length === 1) {
      if (!ctas[0].idCta) {
        notification.error({
          message: 'Error',
          description: 'No CTA found',
        });
        return;
      } else {
        ctaIdList = [ctas[0].idCta];
      }
    } else ctaIdList = selectedCtas;

    try {
      const reportId = await runAddReport({ chatId: externalRef, ctaIdList });
      dispatch(updateConversionReportId({ conversionId: externalRef, reportId }));
      dispatch(getRepostLitThunk()).then(() => {
        navigate(`/report/${reportId}`);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOk = () => {
    addReport();
    handleCancel();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCtas([]);
  };

  const handleSelectionOk = () => {
    setReportSelectionVisible(false);
    setIsModalOpen(true);
  };

  const handleSelectionCancel = () => {
    setReportSelectionVisible(false);
    handleCancel();
  };

  const handleReport = () => {
    if (ctas.length > 1) {
      setReportSelectionVisible(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const getCurrentConversion = () => {
    return conversionList.find(item => item.externalRef === externalRef);
  };

  const renderSelections = () => {
    return (
      <Checkbox.Group style={{ width: '80%' }} value={selectedCtas} onChange={setSelectedCtas}>
        <Row justify="start">
          {ctas.map((item, index) => {
            return (
              <Col span={24} key={index} style={{ textAlign: 'start', marginBottom: '.3rem' }}>
                {item.isWhite ? (
                  <Space>
                    <span>💎</span>
                    <span>{item.cta}</span>
                  </Space>
                ) : (
                  <Checkbox value={item.idCta}>{item.cta}</Checkbox>
                )}
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    );
  };

  return (
    <div style={{ padding: '0 1.5rem' }}>
      <Row>
        <Progress
          percent={(score / 1000) * 100}
          status="active"
          showInfo={false}
          strokeColor={{
            '0%': '#61C26E',
            '25%': '#E9E138',
            '75%': '#F79122',
            '100%': '#FF0000',
          }}
        />
      </Row>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
      <Row justify="end">
        {isComplete && score >= 500 && Array.isArray(ctas) && ctas.length > 0 && (
          <Button
            type="primary"
            style={
              getCurrentConversion()?.reportId
                ? { backgroundColor: '#706f6fbe' }
                : { backgroundColor: '#f7bd40' }
            }
            onClick={handleReport}
            disabled={getCurrentConversion()?.reportId}
            loading={loading}
          >
            Report
          </Button>
        )}
      </Row>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Report Content Selection</div>}
        open={reportSelectionVisible}
        onOk={handleSelectionOk}
        onCancel={handleSelectionCancel}
        centered={true}
        cancelText="Cancel"
        cancelButtonProps={{ shape: 'round', style: { float: 'left', marginLeft: '25%' } }}
        okText="Report"
        okButtonProps={{
          shape: 'round',
          style: { marginRight: '25%' },
          disabled: selectedCtas.filter(item => !item.isWhite).length === 0,
        }}
      >
        <Row justify="center" style={{ textAlign: 'center' }}>
          {renderSelections()}
        </Row>
      </Modal>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Disclaimer</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        cancelText="Cancel"
        cancelButtonProps={{ shape: 'round', style: { float: 'left', marginLeft: '25%' } }}
        okText="Accept"
        okButtonProps={{ shape: 'round', style: { marginRight: '25%' } }}
      >
        <Row justify="center" style={{ textAlign: 'center' }}>
          <Col>
            This Chatbot is not a financlal nor legal advisor, and can sometimes confidently make
            mistakes; always exercise with caution.
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RiskBubble;
