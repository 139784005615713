import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useOutletContext, useLocation } from 'react-router-dom';
import { CloudUploadOutlined, LinkOutlined } from '@ant-design/icons';
import { Bubble, Sender, Attachments } from '@ant-design/x';
import { Button, Flex, message, Result } from 'antd';
import {
  fetchConversationId,
  ConversationChat,
  getConversationMessages,
  updateMessageByConversationER,
  validateUserMuted,
} from '@/services/chat.js';
import CustomBubbleRender from './components/CustomBubbleRender.js';
import { getSignedUrl, uploadToS3 } from '@/services/attachment.js';
import { useDispatch, useSelector } from 'react-redux';
import './index.css';
import HomeSenderView from './components/HomeSenderView.js';
import { getConversionListThunk, updateConversionAttr } from '@/store/modules/globalSlice.js';
// import Compressor from 'compressorjs';
import ChatFooter from '@/components/Footer/ChatFooter.js';
import ChatFooterMobile from '@/components/Footer/ChatFooterMobile.js';
import LoginModal from '@/page/Home/components/LoginModal.js';
import { config } from '@/config/index.js';
import { saveMessageToDB, getMessageFromDB, clearMessageFromDB } from '@/utils/indexedDB.js';
import CountWord from '@/components/CountWord/index.js';

const MAX_INPUT = 2000;

const NewChat = props => {
  const { isFromHome } = props;
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userInputValue, setUserInputValue] = useState('');
  const [fileList, setFileList] = React.useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const senderRef = useRef(null);
  const unsavedMessages = useRef(null);
  const attachmentsRef = useRef(null);
  const { isMobile } = useOutletContext();
  const abortControllerRef = useRef(null);

  const dispatch = useDispatch();
  const { conversionList } = useSelector(state => state.global);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { externalRef } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn && (userInputValue || fileList.length > 0)) {
      const data = {
        text: userInputValue,
        fileList: fileList.map(file => ({
          uid: file.uid,
          name: file.name,
          type: file.originFileObj?.type,
          data: file.originFileObj ? file.originFileObj : null,
        })),
      };
      saveMessageToDB(data);
    }
  }, [userInputValue, fileList]);

  useEffect(() => {
    if (isLoggedIn) {
      initializeConversation();
    }

    return () => {
      setMessages([]);
      setUserInputValue('');
      setFileList([]);
      setOpen(false);
    };
  }, [externalRef, navigate, isLoggedIn]);

  useEffect(() => {
    if (location.pathname === '/') {
      setTimeout(() => {
        onFocus();
      });
    }
  }, [location]);

  const dragCounter = useRef(0);

  useEffect(() => {
    const handleDragEnter = e => {
      e.preventDefault();
      dragCounter.current++;
      if (dragCounter.current === 1) {
        setIsDragging(true);
      }
    };

    const handleDragOver = e => {
      e.preventDefault();
    };

    const handleDragLeave = e => {
      e.preventDefault();
      dragCounter.current--;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDrop = e => {
      e.preventDefault();
      dragCounter.current = 0;
      setIsDragging(false);
      if (e.dataTransfer.files.length > 0) {
        handleFileChange(Array.from(e.dataTransfer.files));
      }
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  const handleLogin = provider => {
    window.location.href = `${config.API_BASE_URL}/oauth2/authorization/${provider}`;
    setIsLoginModalOpen(false);
  };

  const handleFileChange = async newFileList => {
    if (!Array.isArray(newFileList)) return;

    if (newFileList.length === 0) {
      setFileList([]);
      return;
    }

    const validFiles = newFileList
      .map(file => file.originFileObj || file)
      .filter(file => ['image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(file.type));

    if (validFiles.length === 0) return;

    if (validFiles.length > 1) {
      message.error('You can only upload 1 file at most!');
      return;
    }

    let file = validFiles[0];

    if (!(file instanceof Blob)) return;

    if (file.size > 10 * 1024 * 1024) {
      message.error('File size should be less than 10MB!');
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async e => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = async () => {
        let finalFile = file;
        // if (image.width > 1536 || image.height > 1536) {
        //   finalFile = await compressImage(file);
        // }

        setFileList([{ uid: Date.now(), originFileObj: finalFile, name: file.name }]);
        setOpen(true);
      };
    };
  };

  const handleRemoveFile = file => {
    const updatedFileList = fileList.filter(item => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const sharedAttachmentProps = {
    beforeUpload: () => false,
    items: fileList,
    maxCount: 1,
    accept: 'image/jpeg,image/png,image/webp,image/gif',
    onChange: ({ fileList }) => handleFileChange(fileList),
    onRemove: file => handleRemoveFile(file),
    imageProps: {
      height: '100%',
      width: '100%',
    },
  };

  const initializeConversation = async () => {
    if (externalRef && !unsavedMessages.current) {
      const response = await getConversationMessages(externalRef);
      if (response) {
        response.forEach(item => Object.assign(item, { typing: false }));
        setMessages(response);
      } else {
        setMessages([]);
      }
    } else if (externalRef && unsavedMessages.current) {
      setUserInputValue(unsavedMessages.current.text);
      setFileList(unsavedMessages.current.fileList);
      handleSendMessage(unsavedMessages.current.text);
      unsavedMessages.current = null;
    } else {
      const saved = await getMessageFromDB();
      if (saved) {
        setUserInputValue(saved.text || '');
        const rebuiltFileList =
          saved.fileList?.map(file => ({
            ...file,
            originFileObj: file.data,
          })) || [];
        if (rebuiltFileList.length > 0) setOpen(true);
        setFileList(rebuiltFileList);
        clearMessageFromDB();
      }
    }
  };

  const uploadFile = async file => {
    const fileResponse = await getSignedUrl(file);
    await uploadToS3(fileResponse.signedPutUrl, file.originFileObj);
    return fileResponse;
  };

  const onFocus = () => {
    senderRef.current?.focus({
      cursor: 'end',
    });
  };

  // const compressImage = file => {
  //   return new Promise((resolve, reject) => {
  //     new Compressor(file, {
  //       quality: 0.9, // 图片质量 70%
  //       maxWidth: 1536, // 限制最大宽度
  //       maxHeight: 1536, // 限制最大高度
  //       // minWidth‌: 768, // 限制最小宽度
  //       // minHeight‌: 768, // 限制最小高度
  //       mimeType: 'image/jpeg', // 统一转换成 JPEG
  //       success (result) {
  //         const compressedFile = new File([result], file.name, {
  //           type: 'image/jpeg',
  //           lastModified: Date.now(),
  //         });
  //         resolve(compressedFile);
  //       },
  //       error (err) {
  //         console.error('Compression error:', err);
  //         reject(err);
  //       },
  //     });
  //   });
  // };

  const getPlaceholderFn = inlinePlaceholder => {
    return type =>
      type === 'drop'
        ? {
            title: 'Drop file here',
          }
        : inlinePlaceholder;
  };

  const headerNode = (
    <Sender.Header title="Upload" open={open} onOpenChange={setOpen} forceRender>
      <Flex vertical align="center" gap="small">
        <Attachments
          ref={attachmentsRef}
          {...sharedAttachmentProps}
          disabled={loading}
          placeholder={getPlaceholderFn(
            <Result
              icon={<CloudUploadOutlined style={{ fontSize: 32 }} />}
              title={<div style={{ fontSize: 16 }}>Drag and Drop Images Here</div>}
              extra={
                <Button type="primary" size="small">
                  Select Images
                </Button>
              }
              style={{ padding: 0, margin: 0 }}
            />
          )}
        />
      </Flex>
    </Sender.Header>
  );

  const resetSender = () => {
    setFileList([]);
    setUserInputValue('');
    setOpen(false);
  };

  const haveAttachments = () => Array.isArray(fileList) && fileList.length > 0;

  const sendFromHomeAction = async text => {
    if (!isFromHome) return;

    unsavedMessages.current = {
      text,
      fileList,
    };
    try {
      const res = await fetchConversationId();
      dispatch(getConversionListThunk()).then(() => {
        navigate(`/chat/${res.externalRef}`);
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const updateConversionNameAfterChat = (externalRef, name) => {
    if (name) dispatch(updateConversionAttr({ conversionId: externalRef, data: { name } }));
  };

  const handleSendMessage = async (text = '') => {
    if (!isLoggedIn) {
      setIsLoginModalOpen(true);
      return;
    }

    try {
      await validateUserMuted();
    } catch (error) {
      return;
    }

    setLoading(true);
    if (isFromHome) {
      await sendFromHomeAction(text);
      return;
    }

    if (!senderAvailable()) return; // 防止发送空消息

    const imageExternalRefs = [];
    const signedGetUrls = [];
    const previewUrls = [];

    if (haveAttachments()) {
      const res = await Promise.all(fileList.map(uploadFile));
      res.forEach(item => {
        imageExternalRefs.push(item.externalRef);
        signedGetUrls.push(item.signedGetUrl);
        previewUrls.push(item.signedGetUrl);
      });
    }

    // 添加用户消息到对话框
    const appendMessage = {
      content: {
        type: 'text',
        content: text,
      },
      role: 'user',
      placement: 'end',
      typing: false,
    };

    if (haveAttachments()) {
      Object.assign(appendMessage.content, {
        type: 'image',
        content: {
          text,
          imageUrls: previewUrls,
        },
      });
    }

    setMessages(prevMessages => [...prevMessages, appendMessage]);
    resetSender();
    try {
      // 创建新的 AbortController
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      const payload = {
        message: text,
        conversationExternalRef: externalRef,
      };
      if (haveAttachments()) {
        Object.assign(payload, { imageExternalRefs, signedGetUrls });
      }

      const response = await ConversationChat(payload, signal);
      const currentExternalRef = window.location.pathname.split('/').pop();
      updateConversionNameAfterChat(externalRef, response.conversationName);
      if (currentExternalRef === externalRef) {
        setMessages(prevMessages => [...prevMessages, response]);
      }
    } catch (error) {
      const errorMessage = {
        content: {
          type: 'text',
          content: 'Something went wrong. Please try again later.',
        },
        role: 'assistant',
        placement: 'start',
      };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort(); // 取消请求
    }
    setLoading(false);
    updateMessageByConversationER({
      externalRef,
      role: 'user',
      content: { message: 'Request canceled!' },
    });
  };

  const getCurrentConversion = () => {
    return conversionList.find(item => item.externalRef === externalRef);
  };

  const senderAvailable = () => {
    return userInputValue.trim() || (Array.isArray(fileList) && fileList.length);
  };

  const onlySendImage = () => {
    // 输入为空 并且有附件时，直接发送，手动触发onSubmit
    if (!userInputValue.trim() && fileList.length) {
      handleSendMessage();
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onlySendImage();
    }
  };

  const handleOnChange = value => {
    if (value.length > MAX_INPUT) {
      setUserInputValue(value.slice(0, MAX_INPUT));
      message.error(`The maximum number of words is ${MAX_INPUT}`);
    } else {
      setUserInputValue(value);
    }
  };

  const renderDefaultSender = () => {
    return (
      <div style={{ padding: 16 }}>
        <Sender
          ref={senderRef}
          header={headerNode}
          loading={loading}
          placeholder="Type your message..."
          value={userInputValue}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}
          onSubmit={handleSendMessage}
          onCancel={onCancel}
          onPasteFile={file => {
            attachmentsRef.current?.upload(file);
            setOpen(true);
          }}
          autoSize={{ minRows: 2, maxRows: 6 }}
          actions={false}
          footer={({ components }) => {
            const { SendButton, LoadingButton } = components;
            return (
              <Flex justify="space-between" align="center">
                <Flex gap="small" align="center">
                  <Button
                    type="text"
                    icon={<LinkOutlined />}
                    onClick={() => {
                      setOpen(!open);
                    }}
                  />
                </Flex>
                <Flex align="center">
                  <CountWord current={userInputValue?.length} max={MAX_INPUT} />
                  {loading ? (
                    <LoadingButton type="default" />
                  ) : (
                    <SendButton
                      type="primary"
                      disabled={!senderAvailable()}
                      onClick={() => {
                        onlySendImage();
                      }}
                    />
                  )}
                </Flex>
              </Flex>
            );
          }}
        />
      </div>
    );
  };

  const gotoReport = () => {
    const target = getCurrentConversion();
    if (target) {
      navigate('/report/' + target.reportId);
    }
  };

  const goHome = () => {
    navigate('/');
  };

  const renderChatSender = () => {
    let type = '';
    const currentConversion = getCurrentConversion();
    const hasRisk = messages.find(item => item.content?.type === 'risk');
    if (currentConversion && currentConversion.reportId) {
      type = 'reported';
    } else if (hasRisk) {
      type = 'risk';
    }

    switch (type) {
      case 'reported':
        if (isMobile)
          return <ChatFooterMobile content="Jump to the Report Page" onClick={gotoReport} />;
        return (
          <ChatFooter
            content="This dialog has created a report, continue to ask please create a new chat."
            btnText="Jump to the Report Page"
            onClick={gotoReport}
          />
        );
      case 'risk':
        if (isMobile) return <ChatFooterMobile content="New Chat" onClick={goHome} />;
        return (
          <ChatFooter
            content="To continue using the system, create a new chat."
            btnText="New Chat"
            onClick={goHome}
          />
        );
      default:
        return renderDefaultSender();
    }
  };

  const renderContent = () => {
    if (isFromHome) {
      return (
        <div style={{ padding: 16 }}>
          <HomeSenderView />
        </div>
      );
    }

    return (
      <div style={{ flex: 1, overflowY: 'auto', padding: 16 }}>
        <Flex
          gap="middle"
          vertical
          style={{ width: isMobile ? '100%' : '65%', maxWidth: '1200px', margin: '0 auto' }}
        >
          {messages.map(item => (
            <CustomBubbleRender
              key={item.externalRef}
              message={item.content}
              role={item.role}
              createdAt={item.createdAt}
              typing={item.typing}
            />
          ))}

          {loading && (
            <Bubble loading={loading} content="Typing..." role="assistant" placement="start" />
          )}
        </Flex>
      </div>
    );
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {isDragging && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            background: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1000,
            transition: 'opacity 0.3s ease',
          }}
        >
          <CloudUploadOutlined style={{ fontSize: 48, color: '#16796f' }} />
          <p style={{ fontSize: 18, color: '#16796f', marginTop: 10 }}>Drag and Drop Images Here</p>
        </div>
      )}

      {/* 消息展示区域 */}
      {renderContent()}

      {/* 消息输入区域 */}
      <div
        style={{
          position: 'relative',
          bottom: '2%',
          width: isMobile ? '100%' : '65%',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        {renderChatSender()}
      </div>

      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        handleLoginModalClose={handleLoginModalClose}
        handleLogin={handleLogin}
      />
    </div>
  );
};

export default NewChat;
