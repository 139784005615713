import request from '@/utils/request.js';

// 用户相关所有请求

export function reportDetails(fromData) {
  return request({
    url: `/report/details/${fromData}`,
    method: 'GET',
    data: fromData,
  });
}

export function getReportListAPI() {
  return request({
    url: `/report/getReportList`,
    method: 'GET',
  });
}

export async function updateNameAPI(fromData) {
  return await request({
    url: `/report/updateName`,
    method: 'POST',
    data: fromData,
  });
}

export async function addReportAPI(fromData) {
  return await request({
    url: `/report/addReport`,
    method: 'POST',
    data: fromData,
  });
}

export function getReportCta(fromData) {
  return request({
    url: `/report/getReportCta/${fromData}`,
    method: 'GET',
    data: fromData,
  });
}
