import React, { useEffect, useLayoutEffect } from 'react';
import { ConfigProvider, Layout, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from '@/router/routerIndex.js';
import { setThemeMode } from './store/modules/globalSlice.js';

const App = () => {
  const { themeMode } = useSelector(state => state.global);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    let metaViewport = document.querySelector('meta[name="viewport"]');

    if (!metaViewport) {
      metaViewport = document.createElement('meta');
      metaViewport.setAttribute('name', 'viewport');
      document.head.appendChild(metaViewport);
    }

    metaViewport.setAttribute(
      'content',
      'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
    );
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: light)');
    const handleChange = e => {
      dispatch(setThemeMode(e.matches ? 'light' : 'dark'));
    };

    const initTheme = localStorage.getItem('themeMode') ?? (mediaQuery.matches ? 'light' : 'dark');

    // 设置初始主题
    dispatch(setThemeMode(initTheme));

    // 监听主题变化
    mediaQuery.addEventListener('change', handleChange);

    // 清理事件监听器
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const lightConfig = {
    token: {
      colorPrimary: '#16796f',
      colorInfo: '#16796f',
      colorSuccess: '#4caf50',
      colorWarning: '#ff9800',
      colorError: '#f44336',
      colorTextBase: '#212121',
      colorBgBase: '#f5f5f5',
      Layout: {
        headerHeight: 48,
      },
      Menu: {
        itemBg: 'rgba(0,0,0,0)',
        itemHoverBg: '#E0F2F1',
        itemSelectedBg: '#E0F2F1',
        activeBarBorderWidth: 0,
      },
    },
    algorithm: theme.defaultAlgorithm,
  };

  const darkConfig = {
    token: {
      colorPrimary: '#16796f',
      colorInfo: '#16796f',
      colorSuccess: '#4caf50',
      colorWarning: '#ff9800',
      colorError: '#f44336',
      colorTextBase: '#e0e0e0',
      colorBgBase: '#121212',
      Layout: {
        siderBg: '#2F2F2F',
        headerHeight: 48,
        headerBg: '#212121',
      },
      Menu: {
        itemBg: '#2F2F2F',
        itemHoverBg: '#255A58',
        itemSelectedBg: '#255A58',
        itemSelectedColor: '#689e93',
        activeBarBorderWidth: 0,
      },
    },
    algorithm: theme.darkAlgorithm,
  };

  return (
    <ConfigProvider theme={themeMode === 'dark' ? darkConfig : lightConfig}>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </ConfigProvider>
  );
};

export default App;
