import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllConversations } from '@/services/chat.js';
import { getReportListAPI } from '@/services/report.js';
import { getUserProfile } from '@/services/user.js';

export const getUserProfileThunk = createAsyncThunk('global/getUserProfile', async () => {
  return await getUserProfile();
});

export const getConversionListThunk = createAsyncThunk('global/getConversionList', async () => {
  return await getAllConversations();
});

export const getRepostLitThunk = createAsyncThunk('global/getRepostLit', async () => {
  return await getReportListAPI();
});

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    loading: false,
    userProfile: undefined,
    conversionList: [],
    reportList: [],
    themeMode: localStorage.getItem('themeMode') ?? 'light',
    currentTitle: 'Scams.Report',
  },
  reducers: {
    updateUserProfileState(state, action) {
      Object.assign(state.userProfile, action.payload);
    },
    updateConversionAttr(state, action) {
      const target = state.conversionList.find(
        item => item.externalRef === action.payload.conversionId
      );
      if (target) Object.assign(target, action.payload.data);
    },
    updateReportAttr(state, action) {
      const target = state.reportList.find(item => item.externalRef === action.payload.reportId);
      if (target) Object.assign(target, action.payload.data);
    },
    updateConversionReportId(state, action) {
      const target = state.conversionList.find(
        item => item.externalRef === action.payload.conversionId
      );
      if (target) Object.assign(target, { reportId: action.payload.reportId });
    },
    deleteConversion(state, action) {
      state.conversionList = state.conversionList.filter(
        item => item.externalRef !== action.payload
      );
    },
    setThemeMode(state, action) {
      if (['light', 'dark'].includes(action.payload)) {
        localStorage.setItem('themeMode', action.payload);
        state.themeMode = action.payload;
        if (state.userProfile) {
          Object.assign(state.userProfile, { appearance: action.payload });
        }
      }
    },
    setCurrentTitle(state, action) {
      state.currentTitle = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserProfileThunk.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(getConversionListThunk.fulfilled, (state, action) => {
      state.conversionList = action.payload;
    });
    builder.addCase(getRepostLitThunk.fulfilled, (state, action) => {
      state.reportList = action.payload;
    });
    builder
      .addMatcher(
        action => action.type.endsWith('/pending'),
        state => {
          state.loading = true;
        }
      )
      .addMatcher(
        action => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
        state => {
          state.loading = false;
        }
      );
  },
});

export const {
  updateUserProfileState,
  updateReportAttr,
  updateConversionAttr,
  updateConversionReportId,
  deleteConversion,
  setThemeMode,
  setCurrentTitle,
} = globalSlice.actions;

export default globalSlice.reducer;
