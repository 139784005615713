import React, { useEffect } from 'react';
import { Tabs, Button, Row, Col, Typography, Divider } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { reportDetails } from '@/services/report.js';
import TabPane from './components/TabPane.js';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useSelector, useDispatch } from 'react-redux';
import ChatFooterMobile from '@/components/Footer/ChatFooterMobile.js';
import { setRefClearInterval } from '@/store/modules/chatSlice.js';

const { Title } = Typography;

const Report = () => {
  const { externalRef } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useOutletContext();

  const { isLoggedIn } = useSelector(state => state.auth);
  const { reportList } = useSelector(state => state.global);

  const { data, runAsync } = useRequest(reportDetails, {
    manual: true,
  });

  useEffect(() => {
    if (isLoggedIn) {
      runAsync(externalRef);
    }
  }, [externalRef, isLoggedIn]);

  const getCurrentReport = () => {
    return reportList?.find(report => report.externalRef === externalRef);
  };
  const dispatch = useDispatch();
  const onChange = key => {
    dispatch(setRefClearInterval(key));
  };

  const getItems = () => {
    return (
      data?.map(item => {
        return {
          key: item.externalRef,
          label: item.reportCtaRelaName,
          children: <TabPane name={item} />,
        };
      }) || []
    );
  };

  const handleBackToConversion = () => {
    navigate(`/chat/${getCurrentReport()?.chatId}`);
  };

  return (
    <div style={{ padding: '1rem 2rem' }}>
      {!isMobile && (
        <>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4}>{getCurrentReport()?.reportName}</Title>
            </Col>
            <Col>
              <Button shape="round" icon={<HistoryOutlined />} onClick={handleBackToConversion}>
                Return Conversation Log
              </Button>
            </Col>
          </Row>

          <Divider style={{ margin: '1rem' }} />
        </>
      )}

      <Tabs defaultActiveKey={0} items={getItems()} onChange={onChange} size="small" />

      {isMobile && (
        <ChatFooterMobile
          content="Return Conversation Log"
          style={{ position: 'sticky', bottom: '1rem' }}
          onClick={handleBackToConversion}
        />
      )}
    </div>
  );
};

export default Report;
