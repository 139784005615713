import { Button, Image, Row, Space, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import './index.css';
import jump from '@/assets/layout/jump.svg';

const { Text } = Typography;

const ChatFooterMobile = props => {
  const { content, onClick, style } = props;
  const { themeMode } = useSelector(state => state.global);

  return (
    <div className={themeMode === 'dark' ? 'chat-footer-dark' : 'chat-footer'} style={style}>
      <Row justify="center">
        <Space size="large">
          <Text strong>{content}</Text>
          <Button
            size="large"
            type="primary"
            className="jump-btn"
            onClick={onClick}
            icon={<Image src={jump} width={22} preview={false}></Image>}
          ></Button>
        </Space>
      </Row>
    </div>
  );
};

export default ChatFooterMobile;
